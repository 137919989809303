<template>
    <div class="staffTaget-toClass">
        <a-breadcrumb class="breadcrumb">
            <a-breadcrumb-item>首页</a-breadcrumb-item>
            <a-breadcrumb-item>教务数据分析</a-breadcrumb-item>
            <a-breadcrumb-item>请假统计</a-breadcrumb-item>
        </a-breadcrumb>
        <div class="main-box">
            <div class="clearfix table-tools">
                <div class="buttons">
                </div>
                <div class="search">
                    <a-form layout='inline'>
                    <a-form-item>
                    <a-radio-group :value="paramsData.filter_type" @change="handleButChange">
                        <a-radio-button value="day">
                        日
                        </a-radio-button>
                        <a-radio-button value="week">
                        周
                        </a-radio-button>
                        <a-radio-button value="month">
                        月
                        </a-radio-button>
                        <a-radio-button value="year">
                        年
                        </a-radio-button>
                    </a-radio-group>
                    </a-form-item>
                    <a-form-item>
                      <a-radio-group :value="paramsData.book_type">
                        <a-radio-button @click="handleTypeButChange('0')" value="0">
                            约课
                        </a-radio-button>
                        <a-radio-button @click="handleTypeButChange('1')" value="1">
                            排课
                        </a-radio-button>
                      </a-radio-group>
                    </a-form-item>
                    <a-form-item>
                        <a-tooltip placement="topLeft" >
                        <template slot="title">
                            <span>时间筛选</span>
                        </template>
                        <a-range-picker
                            :ranges="rangesData"
                            :value='rangesValue'
                            :disabled-date="disabledDate" :allowClear="false"
                            :default-value="[moment(paramsData.start_date,'YYYY-MM-DD'),moment(paramsData.end_date,'YYYY-MM-DD')]"  style="width: 240px"
                            @change="(date, dateString)=>onChange(date, dateString,1)" />
                        </a-tooltip>
                    </a-form-item>

                    <a-form-item>
                        <a-tooltip placement="topLeft" >
                        <template slot="title">
                            <span>上课校区</span>
                        </template>
                        <a-select allowClear showSearch placeholder="请选择校区" mode="multiple" :maxTagCount="1" :maxTagTextLength='5' :filterOption="filterOption" @change="handleChange" style="width: 220px;">
                            <a-select-option v-for="(d, index) of studios" :key="index" :value="d['studio_id']">{{ d['filter_name'] }}</a-select-option>
                        </a-select>
                        </a-tooltip>
                    </a-form-item>
                    <a-form-item>
                        <a-tooltip placement="topLeft" >
                        <template slot="title">
                            <span>课程科目</span>
                        </template>
                        <a-select allowClear placeholder="请选择课程科目" :filterOption="filterOption" @change="handleSubjectChange" style="width: 180px">
                            <a-select-option v-for="(d, index) of classCategorys" :key="index" :value="d['course_cate_id']">{{ d['filter_name'] }}</a-select-option>
                        </a-select>
                        </a-tooltip>
                    </a-form-item>
                    <a-form-item>
                        <a-tooltip placement="topLeft" >
                        <template slot="title">
                            <span>授课老师</span>
                        </template>
                        <a-select mode="multiple" :maxTagCount="1" :maxTagTextLength='5' v-model='paramsData.employee_id' allowClear showSearch @search="handleSearch" placeholder="请选择授课老师" :filterOption="filterOption" style="width: 180px;">
                        <a-select-option v-for="(d, index) of staffList" :key="index" :value="d['employee_id']">{{ d['filter_name'] }}</a-select-option>
                        </a-select>
                        </a-tooltip>
                    </a-form-item>

                    <a-form-item>
                        <a-button @click="toScreen" :loading="loading" type="primary">筛选</a-button>
                    </a-form-item>
                    </a-form>
                </div>
            </div>
            <div class="dashboard bk-group">
                <statistics :count='counts' :col="6"/>
                <a-row class="mb16" :gutter="16">
                    <a-col :lg="24" :xl="24">
                        <absenceSummate title="学员请假统计" :loading='loadingS'  :leads_data ='absenceSData' :start_date='paramsData.start_date' :end_date='paramsData.end_date' />
                    </a-col>
                    <a-col :lg="24" :xl="24">
                        <div style="background:#fff;">
                            <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
                            <a-table size="small" :pagination="false" :bordered='false' rowKey="studio_id"
                            :columns="columns" :dataSource="list" :scroll="{ x: RelativeWidth, y:500}">
                              <span slot="arragement_total_title">
                                <a-tooltip>
                                  <template slot="title">
                                    排课学员/约课学员的请假比例
                                  </template>
                                  排课<a-icon style="margin-left:5px" type="question-circle" />
                                </a-tooltip>
                              </span>
                              <span slot="appointment_total_title">
                                <a-tooltip>
                                  <template slot="title">
                                    约课学员/约课学员的请假比例
                                  </template>
                                  约课<a-icon style="margin-left:5px" type="question-circle" />
                                </a-tooltip>
                              </span>
                              <span slot="temporary_total_title">
                                <a-tooltip>
                                  <template slot="title">
                                    插班学员/插班学员的请假比例
                                  </template>
                                  插班<a-icon style="margin-left:5px" type="question-circle" />
                                </a-tooltip>
                              </span>
                              <span slot="noshow_total_title">
                                <a-tooltip>
                                  <template slot="title">
                                    学员未请假，未出勤/占比例
                                  </template>
                                  鸽子<a-icon style="margin-left:5px" type="question-circle" />
                                </a-tooltip>
                              </span>
                              <span slot="total_title">
                                <a-tooltip>
                                  <template slot="title">
                                    学员请假/占比例
                                  </template>
                                  请假<a-icon style="margin-left:5px" type="question-circle" />
                                </a-tooltip>
                              </span>

                              <template slot="arragement_total" slot-scope="text, record">
                                <div>
                                  <span >{{text}}</span>
                                  <br/>
                                  <span style="color:rgba(69, 90, 100, 0.4)">({{Number(record.arragement_precent).toFixed(2)}}%)</span>
                                </div>
                              </template>
                              <template slot="appointment_total" slot-scope="text, record">
                                <div>
                                  <span >{{text}}</span>
                                  <br/>
                                  <span style="color:rgba(69, 90, 100, 0.4)">({{Number(record.appointment_precent).toFixed(2)}}%)</span>
                                </div>
                              </template>
                              <template slot="temporary_total" slot-scope="text, record">
                                <div>
                                  <span >{{text}}</span>
                                  <br/>
                                  <span style="color:rgba(69, 90, 100, 0.4)">({{Number(record.temporary_precent).toFixed(2)}}%)</span>
                                </div>
                              </template>
                              <template slot="noshow_total" slot-scope="text, record">
                                <div>
                                  <span >{{text}}</span>
                                  <br/>
                                  <span style="color:rgba(69, 90, 100, 0.4)">({{Number(record.noshow_precent).toFixed(2)}}%)</span>
                                </div>
                              </template>
                              <template slot="total" slot-scope="text, record">
                                <div>
                                  <span >{{text}}</span>
                                  <br/>
                                  <span style="color:rgba(69, 90, 100, 0.4)">({{record.precent}})</span>
                                </div>
                              </template>
                              <template v-for="(it,j) in name_list" :slot="it.filed_name" slot-scope="text, record , index">
                                <div :key="j">
                                  <span >{{text}}</span>
                                  <br/>
                                  <span style="color:rgba(69, 90, 100, 0.4)">({{record[`${it.filed_name}F`]}}%)</span>
                                </div>
                              </template>
                            </a-table>
                        </div>
                    </a-col>

                    <a-col :lg="24" :xl="24">
                        <div style="background:#fff;">
                            <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
                            <a-table size="small" :pagination="false" :bordered='false' rowKey="employee_id"
                            :columns="employee_columns" :dataSource="employee_list" :scroll="{ x: RelativeWidth, y:500}">
                              <span slot="arragement_total_title">
                                <a-tooltip>
                                  <template slot="title">
                                    排课学员/约课学员的请假比例
                                  </template>
                                  排课<a-icon style="margin-left:5px" type="question-circle" />
                                </a-tooltip>
                              </span>
                              <span slot="appointment_total_title">
                                <a-tooltip>
                                  <template slot="title">
                                    约课学员/约课学员的请假比例
                                  </template>
                                  约课<a-icon style="margin-left:5px" type="question-circle" />
                                </a-tooltip>
                              </span>
                              <span slot="temporary_total_title">
                                <a-tooltip>
                                  <template slot="title">
                                    插班学员/插班学员的请假比例
                                  </template>
                                  插班<a-icon style="margin-left:5px" type="question-circle" />
                                </a-tooltip>
                              </span>
                              <span slot="noshow_total_title">
                                <a-tooltip>
                                  <template slot="title">
                                    学员未请假，未出勤/占比例
                                  </template>
                                  鸽子<a-icon style="margin-left:5px" type="question-circle" />
                                </a-tooltip>
                              </span>
                              <span slot="total_title">
                                <a-tooltip>
                                  <template slot="title">
                                    学员请假/占比例
                                  </template>
                                  请假<a-icon style="margin-left:5px" type="question-circle" />
                                </a-tooltip>
                              </span>

                              <template slot="arragement_total" slot-scope="text, record">
                                <div>
                                  <span >{{text}}</span>
                                  <br/>
                                  <span style="color:rgba(69, 90, 100, 0.4)">({{Number(record.arragement_precent).toFixed(2)}}%)</span>
                                </div>
                              </template>
                              <template slot="appointment_total" slot-scope="text, record">
                                <div>
                                  <span >{{text}}</span>
                                  <br/>
                                  <span style="color:rgba(69, 90, 100, 0.4)">({{Number(record.appointment_precent).toFixed(2)}}%)</span>
                                </div>
                              </template>
                              <template slot="temporary_total" slot-scope="text, record">
                                <div>
                                  <span >{{text}}</span>
                                  <br/>
                                  <span style="color:rgba(69, 90, 100, 0.4)">({{Number(record.temporary_precent).toFixed(2)}}%)</span>
                                </div>
                              </template>
                              <template slot="noshow_total" slot-scope="text, record">
                                <div>
                                  <span >{{text}}</span>
                                  <br/>
                                  <span style="color:rgba(69, 90, 100, 0.4)">({{Number(record.noshow_precent).toFixed(2)}}%)</span>
                                </div>
                              </template>
                              <template slot="total" slot-scope="text, record">
                                <div>
                                  <span >{{text}}</span>
                                  <br/>
                                  <span style="color:rgba(69, 90, 100, 0.4)">({{record.precent}})</span>
                                </div>
                              </template>
                              <template v-for="(it,j) in name_list" :slot="it.filed_name" slot-scope="text, record , index">
                                <div :key="j">
                                  <span >{{text}}</span>
                                  <br/>
                                  <span style="color:rgba(69, 90, 100, 0.4)">({{record[`${it.filed_name}F`]}}%)</span>
                                </div>
                              </template>
                            </a-table>
                        </div>
                    </a-col>

                    <a-col :lg="24" :xl="12">
                        <LRanking title="校区学员缺勤缺勤统计" :rank_data="studioData" :loading="loading" :start_date='paramsData.start_date' :end_date='paramsData.end_date' />
                    </a-col>
                    <a-col :lg="24" :xl="12">
                        <LRanking title="授课老师学员缺勤统计" :rank_data="absenceData" :loading="loading" :start_date='paramsData.start_date' :end_date='paramsData.end_date' />
                    </a-col>
                    <a-col :lg="24" :xl="12">
                        <LRanking title="教室学员缺勤统计" :rank_data="roomData" :loading="loading" :start_date='paramsData.start_date' :end_date='paramsData.end_date' />
                    </a-col>
                    <a-col :lg="24" :xl="12">
                        <LRanking title="课程学员缺勤统计" :rank_data="absenceCData" :loading="loadingC" :start_date='paramsData.start_date' :end_date='paramsData.end_date' />
                    </a-col>
                </a-row>
            </div>
        </div>
    </div>
</template>

<script>
import ranges from "@/common/mixins/ranges"
import moment from 'moment'
import absence from './absence'
import absenceCourse from './absenceCourse'
import absenceSummate from './absenceSummate'
import statistics from '@/views/bi/ranking/money/statistics'

export default {
  name: 'qjsjtj',
  components:{
    absence,
    absenceCourse,
    absenceSummate,
    statistics
  },
  computed: {
    RelativeWidth() {
        let width = 800
        if(this.filedLength >7){
          width = 800 + ( this.filedLength - 7 ) * 130
        }
        return width
    }
  },
  data() {
    return {
      loading:false,
      loadingC:false,
      loadingS:false,
      studioData:{},
      roomData:{},
      absenceData:{},
      absenceCData:{},
      absenceSData:{},
      studios:[],
      counts:[],
      staffList:[],
      classCategorys:[],
      paramsData:{
        start_date:moment().startOf('month').format('YYYY-MM-DD'),
        end_date:moment().format('YYYY-MM-DD'),
        filter_type:'day',
        studio_id:'',
        course_cate_id:''
      },
      list: [],
      employee_columns:[],
      employee_list:[],
      columns:[],
      name_list:[],
      filedLength:0,
    }
  },
  mixins: [ranges],
  created() {
    this.rangesValue = [moment(this.paramsData.start_date,'YYYY-MM-DD'),moment(this.paramsData.end_date,'YYYY-MM-DD')]
    this.toScreen()
    this.getStaff()
    this.getStudio()
    this.getClassCategory()
  },

  methods: {
    moment,
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    async getList(obj){
      this.getCount(obj)
      this.getAbsence(obj)
      this.getAbsenceCourse(obj)
      this.getAbsenceSummate(obj)
      this.getAbsenceAmount(obj)
    },
    async getAbsence(obj){
      this.loading = true
      let res = await this.$store.dispatch('analysisAbsenceAction', obj)
      this.studioData = res.data.studio
      this.roomData = res.data.room
      this.absenceData = res.data.teacher
      this.loading = false
    },
    async getAbsenceCourse(obj){
      this.loadingC = true
      let res = await this.$store.dispatch('analysisAbsenceCourseAction', obj)
      this.absenceCData = res.data
      this.loadingC = false
    },
    async getAbsenceSummate(obj){
      this.loadingS = true
      let res = await this.$store.dispatch('analysisAbsenceSummateAction', obj)
      this.absenceSData = res.data
      this.loadingS = false
    },
    async getAbsenceAmount(obj){
      this.loadingS = true
      let res = await this.$store.dispatch('analysisAbsenceAmountAction', obj)
      let list = res.data.studio_list
      list.forEach(item=>{
          item.total = this.paramsData.filter_column == 'money'?this.filterNum(item.total) : item.total
          item.filed.forEach(it=>{
              item[it.filed_name] = this.paramsData.filter_column == 'money'?this.filterNum(it.filed_value):it.filed_value
              item[`${it.filed_name}F`] = it.filed_precent
          })
      })
      let week = [
          { title: '#', width:'55px', customRender:(text,record,index)=>`${index+1}`, key: 'index', fixed: 'left'},
          { title: '校区名称', width:'160px', dataIndex: 'studio_name',  key: 'studio_name', scopedSlots: { customRender: 'name' }, fixed: 'left',ellipsis: true},
          { title: '总数', width:'80px', dataIndex: 'book_total', key: 'book_total',fixed: 'left',align:'right'},
          { width:'80px', dataIndex: 'arragement_total', key: 'arragement_total', slots: { title: 'arragement_total_title' }, scopedSlots: { customRender: 'arragement_total' }, fixed: 'left',align:'right'},
          { width:'80px', dataIndex: 'appointment_total', key: 'appointment_total', slots: { title: 'appointment_total_title' }, scopedSlots: { customRender: 'appointment_total' }, fixed: 'left',align:'right'},
          { width:'80px', dataIndex: 'temporary_total', key: 'temporary_total', slots: { title: 'temporary_total_title' }, scopedSlots: { customRender: 'temporary_total' }, fixed: 'left',align:'right'},
          { width:'80px', dataIndex: 'noshow_total', key: 'noshow_total', slots: { title: 'noshow_total_title' }, scopedSlots: { customRender: 'noshow_total' }, fixed: 'left',align:'right'},
          { width:'80px', dataIndex: 'total', key: 'total', slots: { title: 'total_title' }, scopedSlots: { customRender: 'total' }, fixed: 'left',align:'right'},
      ]
      if(list[0]){
        this.name_list = list[0].filed
        list[0].filed.forEach(item=>{
            let obj = { title: item.filed_name, dataIndex: item.filed_name, key: item.filed_name,align:'right',scopedSlots: { customRender: item.filed_name }}
            week.push(obj)
        })
      }
      if(list[0]){
        this.filedLength = list[0].filed.length
      }else{
        this.filedLength = 0
      }

      this.columns = week
      this.list = list


      let employee_list = res.data.employee_list
      employee_list.forEach(item=>{
          item.total = this.paramsData.filter_column == 'money'?this.filterNum(item.total) : item.total
          item.filed.forEach(it=>{
              item[it.filed_name] = this.paramsData.filter_column == 'money'?this.filterNum(it.filed_value):it.filed_value
              item[`${it.filed_name}F`] = it.filed_precent
          })
      })
      let employee_week = [
          { title: '#', width:'55px', customRender:(text,record,index)=>`${index+1}`, key: 'index', fixed: 'left'},
          { title: '员工名称', width:'160px', dataIndex: 'employee_name',  key: 'employee_name', scopedSlots: { customRender: 'name' }, fixed: 'left',ellipsis: true},
          { title: '总数', width:'80px', dataIndex: 'book_total', key: 'book_total',fixed: 'left',align:'right'},
          { width:'80px', dataIndex: 'arragement_total', key: 'arragement_total', slots: { title: 'arragement_total_title' }, scopedSlots: { customRender: 'arragement_total' }, fixed: 'left',align:'right'},
          { width:'80px', dataIndex: 'appointment_total', key: 'appointment_total', slots: { title: 'appointment_total_title' }, scopedSlots: { customRender: 'appointment_total' }, fixed: 'left',align:'right'},
          { width:'80px', dataIndex: 'temporary_total', key: 'temporary_total', slots: { title: 'temporary_total_title' }, scopedSlots: { customRender: 'temporary_total' }, fixed: 'left',align:'right'},
          { width:'80px', dataIndex: 'noshow_total', key: 'noshow_total', slots: { title: 'noshow_total_title' }, scopedSlots: { customRender: 'noshow_total' }, fixed: 'left',align:'right'},
          { width:'80px', dataIndex: 'total', key: 'total', slots: { title: 'total_title' }, scopedSlots: { customRender: 'total' }, fixed: 'left',align:'right'},
      ]
      if(employee_list[0]){
        this.name_list = employee_list[0].filed
        employee_list[0].filed.forEach(item=>{
            let obj = { title: item.filed_name, dataIndex: item.filed_name, key: item.filed_name,align:'right',scopedSlots: { customRender: item.filed_name }}
            employee_week.push(obj)
        })
      }

      this.employee_columns = employee_week
      this.employee_list = employee_list

      this.loadingS = false
    },
    async getStudio() {
      let res = await this.$store.dispatch('searchBelongStudioAction', {})
      this.studios = res.data
    },
    async getClassCategory() {
      let res = await this.$store.dispatch('searchCourseTypeAction', {})
      this.classCategorys = res.data
    },
    async getCount(obj){
      let res = await this.$store.dispatch('analysisClassesCountAction', obj)
      this.counts = res.data
    },
    async getStaff(val){
      let obj = {q:val}
      let res = await this.$store.dispatch('searchAccountEmployeeAction', {params:obj})
      this.staffList = res.data
    },
    handleButChange(e){
      this.paramsData.filter_type = e.target.value
      this.toScreen()
    },
    handleChange(value){
      this.paramsData.studio_id = value
    },
    handleSubjectChange(value){
      this.paramsData.course_cate_id = value
    },
    handleSearch(val){
        this.getStaff(val)
    },
    handleTypeButChange(e){
      if(this.paramsData.book_type == e){
        this.paramsData.book_type = undefined
      }else{
        this.paramsData.book_type = e
      }
      this.toScreen()
    },
    toScreen(){
      let obj = {
        search:this.paramsData
      }
      this.getList(obj);
    }
  }
}
</script>


<style lang="scss">
  .staffTaget-toClass{
    .ant-table-row{
      td:nth-child(2){
        background: rgb(229, 244, 254);
      }
      td:nth-child(3){
        background: rgb(229, 244, 254);
      }
      td:nth-child(4){
        background: rgb(229, 244, 254);
      }
      td:nth-child(5){
        background: rgb(229, 244, 254);
      }
      td:nth-child(6){
        background: rgb(229, 244, 254);
      }
      td:nth-child(7){
        background: rgb(229, 244, 254);
      }
      td:nth-child(8){
        background: rgb(229, 244, 254);
      }
    }
  }
</style>