<template>
  <a-row class="statistics-group" :gutter="16">
    <a-col v-for="(item,index) in count" :key="index" class="statistics-panel" :xs="12" :lg="6" :xl="col?col:4">
      <div class="statistics-item">
        <div class="tit">{{item.title}}</div>
        <div class="con"><strong>{{item.value}}</strong></div>
        <div class="foo">{{item.value_unit}}</div>
      </div>
    </a-col>
  </a-row>
</template>

<script>
export default {
  name: 'Statistics',
  props:['count','col']
}
</script>
